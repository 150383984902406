import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import Seo from "../components/seo"
import CarouselSlider from "../components/carousel-slider"
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '../../node_modules/@splidejs/react-splide/dist/css/splide.min.css'

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const postData = data.webiny.listAlphaBetasPosts.data
  const newsData = data.webiny.listAlphaBetasNews.data


  const youTubeVideos = [
    {
      id: 1,
      url: 'https://www.youtube.com/embed/_PUt6q8KRsE',
      title: 'Alpha Betas - This is Alpha Team (Pilot Episode)',
    },
    {
      id: 2,
      url: 'https://www.youtube.com/embed/LaukYn0FC2k',
      title: 'Alpha Betas - One of Us is Getting Canceled! (Episode 2)',
    },
    {
      id: 3,
      url: 'https://www.youtube.com/embed/DGClFUvbnOg',
      title: 'Alpha Betas - The Zombie Deathrun (Episode 3)',
    },
    {
      id: 4,
      url: 'https://www.youtube.com/embed/MMfsYcmbrjc',
      title: 'Alpha Betas - The Filthy Password (Episode 4)',
    },
    {
      id: 5,
      url: 'https://www.youtube.com/embed/zxH6h5YRSKo',
      title: 'Alpha Betas - One Last Mission (Episode 5)',
    },
  ];

  if (newsData.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="Alpha Betas News" />
        <p>
          No Alpha Betas News was found.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} className="bg-black">
      <Seo title="Watch Alpha Betas" />
      
      <ParallaxProvider>
      <div className="bg-hq-cia-operations-mobile md:bg-hq-cia-operations bg-cover bg-center pb-20">
            <div className="bg-body-pattern bg-cover text-white text-sm relative z-40 max-w-7xl mx-auto px-10">
                <div className="grid grid-cols-auto md:grid-cols-6 gap-4 pt-32">
                  <div className="md:col-span-3">
                    <h1 className="text-center md:text-left text-4xl md:text-5xl shadow-2xl font-GodaBold uppercase mb-5 text-ab-yellow-600">What's new in alpha betas</h1>
                    <p className="text-center md:text-left font-CobeSemiBold text-xl leading-6 md:text-2xl">Get a glimpse of Alpha Team's workplace shenanigans through the lens of Spunc. Bonus episode premieres March 23rd, 2023.</p>
                  </div>
                  <div className="md:col-span-3 flex gap-4 items-center justify-center md:justify-end">
                      <a href="https://www.youtube.com/c/AlphaBetas" target="_blank" className="before:content-playbtnBlk before:block before:w-12 before:h-12 w-64 p-2 text-center border-2 font-CobeBold antialiased border-black rounded-xl text-xl bg-ab-yellow-600 text-black my-5 md:my-14 flex gap-4 items-center hover:scale-110 ease-in-out duration-500">Visit our Channel</a>
                  </div>
                </div>
                <Parallax speed={-5}>
                <div className="aspect-w-16 aspect-h-9 my-0 mt-10 md:mt-20">
                <iframe 
                  src='https://www.youtube.com/embed/MSXZxrM5GM4?autoplay=1&mute=1'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                  title='Alpha Betas Show'
                  className="rounded-3xl" 
                  frameborder="0" 
                  allowfullscreen
                />
                </div>
                </Parallax>
            </div>
          <div className="h-72 lg:h-full bg-gradient-to-b from-black absolute w-full top-0 lg:bottom-0 z-0" />
      </div>
      </ParallaxProvider>

      <div className="bg-ab-blue-100 w-full py-10 md:py-20 px-10">
        <div className="block max-w-7xl mx-auto">
          <h2 className="text-center md:text-left w-full md:w-8/12 lg:w-full text-4xl md:text-5xl font-GodaBold uppercase text-ab-yellow-600">Episode Gallery</h2>
          <div className="w-full md:w-8/12 lg:w-full mb-5 md:mb-10">
            <p className="text-center md:text-left font-CobeSemiBold text-xl leading-6 md:text-2xl">Watch all episodes and bonus content here</p>
          </div>
          <Splide 
            options={ {
              rewind: true,
              pagination: false,
              width : '100%',
              perPage: 2,
              gap   : '3rem',
              drag   : 'free',
              breakpoints: {
                1024: {
                  perPage: 2,
                },
                768: {
                  perPage: 1,
                  snap: false,
                },
              },
            } }
            className="CarouselSlider">
              {youTubeVideos.map((video) => (
                <SplideSlide key={video.id} className="py-2">
                  <div className="mb-5 lg:mb-0">
                      <div className="rounded-lg bg-cover overflow-hidden">
                      <iframe
                        src={video.url}
                        width="100%" 
                        height="315"
                        style={{ maxWidth: '100%', maxHeight: '50vh' }}
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="YouTube video player"
                        className="rounded-3xl hover:scale-105 ease-in-out duration-300"
                      />
                      </div>
                      <h2 className="my-3 leading-6 text-lg font-CobeSemiBold">
                      <div className="w-full">{video.title}</div>
                      </h2>
                  </div>
                </SplideSlide>
              ))}
          </Splide>
        </div>
      </div>

      <div className="bg-ab-blue-200 w-full py-10 md:py-20 px-10">
        <div className="block max-w-7xl mx-auto">
          <h2 className="text-center md:text-left w-full md:w-8/12 lg:w-full text-4xl md:text-5xl font-GodaBold uppercase text-ab-yellow-600">Deleted Scenes</h2>
          <div className="w-full md:w-8/12 lg:w-full mb-5 md:mb-10">
            <p className="text-center md:text-left font-CobeSemiBold text-xl leading-6 md:text-2xl">NSFYT (Not Safe For YouTube) scenes that were removed from episodes</p>
          </div>
            <CarouselSlider carousel={postData} slidesPerPage={2} type="postData" />
        </div>
      </div>

      <div className="bg-bodyPattern w-full py-10 md:py-20 px-10">
        <div className="block max-w-7xl mx-auto">
          <h2 className="text-center md:text-left w-full md:w-8/12 lg:w-full text-4xl md:text-5xl font-GodaBold uppercase text-ab-yellow-600">Latest News</h2>
          <div className="w-full md:w-8/12 lg:w-full mb-5 md:mb-10">
            <p className="text-center md:text-left font-CobeSemiBold text-xl leading-6 md:text-2xl">See what's new in the Alpha Betas community!</p>
          </div>
            <CarouselSlider carousel={newsData} slidesPerPage={3} type="newsData" />
        </div>
      </div>

  </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    webiny {
      listAlphaBetasNews(sort: datePublished_DESC) {
        data {
          title
          visibility
          slug
          shortDescription
          id
          heroImage
          entryId
          datePublished
          body
        }
      }
      listAlphaBetasPosts(sort: createdOn_ASC) {
        data {
          id
          title
          slug
          heroImage
          description
          createdOn
          featuredImage
          textTracks
          visibility
          video
        }
      }
    }
  }
`
